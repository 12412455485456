html {
  font-size: 14px;

  @media (min-width: 768px) {
    font-size: 16px;
  }

  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.companyLogo {
  width: 25vw;
  background-size: cover;
}

.bgstyle {
  background-size: 1200% 1200%;
  height: 100%;
}

.bg-gradient {
  background: linear-gradient(rgba(245, 245, 245, 1), rgba(245, 245, 245, 0));
}

.navbar-transparent {
  background-color: rgba(255, 255, 255, 0);
  transition: background-color 0.2s;
}

.navbar-white {
  background-color: rgba(234, 236, 239, 0.81);
  transition: background-color 0.2s;
}

.brand {
  color: gold !important;
}

.nav-link {
  transition: color, 0.2s;
}

.toggler {
  border: none;
  padding: 4px;
  border-radius: 4px;

  &:focus {
    outline: none;
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.socialicons {
  color: Gold;
  padding-left: 5px;
  padding-right: 5px;
  transition: all 0.8s;
  margin: 4px;

  &:hover {
    color: black;
    padding-left: 5px;
    padding-right: 5px;
    transition: all 0.8s;
    margin: 4px;
  }
}

.title {
  height: 100%;
  left: 0;
  right: 0;
  color: #fff;
  text-align: center;
  margin-top: 0px;
}

.typist {
  min-height: 6rem;

  @media (min-width: 360px) {
    min-height: 4rem;
  }

  @media (min-width: 768px) {
    min-height: 2.5rem;
  }
}

.roundCorners {
  border-radius: 25px;
  background: rgb(255, 215, 0, 0.6);
  padding: 10px;
}

.card {
  transition: transform 0.3s, box-shadow 0.3s;

  &:hover {
    box-shadow: 0 2rem 3rem rgba($black, 0.175) !important;
    transform: translateY(-2px);
    transition: transform 0.3s, box-shadow 0.3s;
  }
}

.skills-tabs {
  flex-grow: 1;
}

.skills-tab {
  flex-grow: 1;
  text-align: center;
}

.progress {
  transform-origin: left;

  &>* {
    transform: scaleX(0);
  }
}

.progress-bar-animation {
  @extend .progress;

  &>* {
    animation: progress 1.5s ease-in-out forwards;
  }
}

.filmsContainer {
  margin-top: 100px;
  padding-top: 100px;
}

.paperContainer {
  margin-top: 10px;
  background-color: #b91616;
  position: relative;
}

.videoPaperContainer {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  // position: flex;
}

.portfolioTitle {
  margin-top: 100px;
  position: relative;
}

.video-responsive {
  margin-top: 10px;
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 56.25%;
  /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
}

/* Then style the iframe to fit in the container div with full height and width */
.responsive-iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}